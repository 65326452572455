import React, { Component, Suspense, lazy } from 'react'
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

const ChatRoom = lazy(() => import('./components/ChatPointX/Routes/ChatRoom'));
const ResetPassword = lazy(() => import('./components/ChatPointX/Routes/ResetPassword'));
const PublicRegistration = lazy(() => import('./components/ChatPointX/Routes/PublicRegistration'));

class App extends Component {
    render() {
        return (
            <Router>
                <Suspense fallback={<div className="loading"></div>}>
                    <Switch>
                        <Route exact path="/app/pub-user-reg" component={PublicRegistration}/>
                        <Route exact path="/app/password_reset" component={ResetPassword}/>
                        <Route path="/app" component={ChatRoom}/>
                    </Switch>
                </Suspense>
            </Router>
        )
    }
}
export default App