/* 
------------------------------------------------------
1) Adding more languages
  a) Add an additional language file
  b) Add the additional translations on init
  c) Auto detect the user language
  d) Let the user toggle the language
------------------------------------------------------
2) Lazy loading translations
  a) Adding lazy loading for translations
  b) Loading multiple translation files
------------------------------------------------------
3) Sidequest: natural vs. keybased catalog
  a) natural keys
  b) keybased catalog
------------------------------------------------------
https://react.i18next.com/legacy-v9/step-by-step-guide
*/
import i18n from "i18next"
import detector from "i18next-browser-languagedetector" //yarn add i18next-browser-languagedetector --save
import backend from "i18next-xhr-backend"               //yarn add i18next-xhr-backend --save
import { initReactI18next } from "react-i18next"      //yarn add react-i18next@legacy i18next --save

// After adding lazy loading for translations
// translations are already at '../public/locales/en/translation.json'
// which is the default for the xhr backend to load from
/* -- i18n Translation Resources --
import translationEN from '../public/locales/en/translation.json'
import translationCN from '../public/locales/cn/translation.json'
const resources = {
  en: {
    translation: translationEN
  },
  cn: {
    translation: translationCN
  },
}
*/
i18n
  .use(detector)            // Auto detect the user language
  .use(backend)             // Adding lazy loading for translations
  .use(initReactI18next)  // Passes i18n down to react-i18next
  .init({
    //resources,            // After adding lazy loading for translations
                            // Without configuration i18next will always load one file (namespace) named translation. Learn more about namespaces here.
                            //https://www.i18next.com/principles/namespaces
                            //https://react.i18next.com/legacy-v9/withnamespaces
    //lng: "en",
    //fallbackLng: "en",      // Use ["en", "fr"] if detected lng is not available
    fallbackLng: { 
      'zh-tw': ['zh-hk'],
      'default': ['en']
    },
    //whitelist: ["en", "zh-hk", "zh-cn", "ja", "ko", "fr"],
    
    //keySeparator: false,    // "Natural Keys"
                            // we had organised translation and keys in natural language
                            // In order to use "Keybased Catalog", removing keySeparator: false
    interpolation: {
      escapeValue: false    // react already safes from xss
    },

    load: "currentOnly",
    lowerCaseLng: true,
    
    //For production only
    backend: {
      loadPath: '/app/locales/{{lng}}/{{ns}}.json',
    }

    // react-i18next Options for V9, and V10 have more options
    
    /*react: {
      useSuspense: false
    }*/
  })
/* -- Information --
 * i18next implementation is smart enough to only load needed languages 
 * and comes with intelligent deduplications 
 * so even multiple load requests for files in different code locations 
 * result in one request while notifying all needed requester.
 */
export default i18n