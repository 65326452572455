import React from 'react'
import ReactDOM from 'react-dom'
import * as serviceWorker from './serviceWorker'
import './i18n'
import App from './App'
import './index.css'
// React 17 -> 
ReactDOM.render(<App />, document.getElementById("root"));

serviceWorker.register()
//...Following Codes relocated after Login is Successful